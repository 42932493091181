import React from 'react';

function Footer() {
  return (
    <footer className='py-16 flex flex-col justify-items-center items-center max-w-3xl mx-auto inset-x-0 bottom-0'>
      <div className='flex flex-col justify-items-center items-center mb-4'>
        <div className='flex'>
          <img src='/Vector.png' alt='logo' className='w-10 h-10 mr-3' />
          <span className='text-2xl font-semibold mt-2 '>ScoresAreUs</span>
        </div>

        <div className='text-sm'>info@scoresareus.com</div>
      </div>
      {/* <ul className='list-unstyled row flex space-x-28'>
        <li className='col-sm-4'>
          <h5 className='font-bold mb-3'>Pages</h5>
          <ul>
            <li className='text-gray-500 mb-2'>Home</li>
            <li className='text-gray-500 mb-2'>Our Service</li>
            <li className='text-gray-500 mb-2'>Portfolio</li>
            <li className='text-gray-500 mb-2'>Testimonial</li>
            <li className='text-gray-500 mb-2'>Contact Us</li>
          </ul>
        </li>
        <li className='col-sm-4'>
          <h5 className='font-bold mb-3'>Explore</h5>
          <ul>
            <li className='text-gray-500 mb-2'>Resource</li>
            <li className='text-gray-500 mb-2'>Blog</li>
            <li className='text-gray-500 mb-2'>Documents</li>
          </ul>
        </li>
        <li className='col-sm-4'>
          <h5 className='font-bold mb-3'>Company</h5>
          <ul>
            <li className='text-gray-500 mb-2'>About Us</li>
            <li className='text-gray-500 mb-2'>Partners</li>
            <li className='text-gray-500 mb-2'>Customers</li>
            <li className='text-gray-500 mb-2'>Contact Us</li>
          </ul>
        </li>
      </ul> */}
      <div className='flex text-sm'>
      <b>*Offered through partners and licensees</b>
      </div>
      <div className='flex text-sm'>
        Copyright © {new Date().getFullYear()} ScoresAreUs. All rights reserved.
      </div>
    </footer>
  );
}

export default Footer;
