import React from 'react';

function Service() {
  const services = [
    {
      imageSrc: '/image/icon/integration.png',
      title: 'Seamless Integration',
      description:
        'Integrate with your existing financial infrastructure, minimizing disruptions and maximizing efficiency.        ',
    },
    {
      imageSrc: '/image/icon/solution.png',
      title: ' Customizable Solutions',
      description:
        ' Tailor our addons to align with your unique financial needs and business requirements.',
    },
    {
      imageSrc: '/image/icon/stats.png',
      title: 'Real-time Insights',
      description:
        ' Gain real-time insights into your financial activities, allowing you to make informed decisions promptly.',
    },
    {
      imageSrc: '/image/icon/lock.png',
      title: 'Enhanced Security',
      description:
        ' Safeguard your financial data with advanced security features, ensuring your confidential information remains protected.',
    },
    {
      imageSrc: '/image/icon/tv.png',
      title: 'User-Friendly Interface',
      description:
        ' Our intuitive interfaces make navigating and utilizing our addons a breeze, even for those new to financial management tools.',
    },
  ];

  return (
    <section id='Service' className='my-8 max-w-5xl mx-auto inset-x-0 bottom-0'>
      <p className='text-lg'>Our Services*</p>
      <h2 className='text-3xl font-bold'>We Provide What You Need</h2>
      <div className='mt-10 grid grid-cols-3 -md:grid-cols-2 -sm:grid-cols-1  gap-14 justify-items-center'>
        {services.map((service, index) => (
          <div key={index} className='w-64 px-4 py-10 text-center shadow-lg'>
            <img
              src={service.imageSrc}
              alt={service.title}
              className='mx-auto mb-3'
              style={{ height: '50px' }}
            />
            <h4 className='text-xl mb-2'>{service.title}</h4>
            <p className='text-gray-500'>{service.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
}
export default Service;
