import React from 'react';
function Project() {
  const projects = [
    {
      image: '/image/project/payroll.jpg',
      title: 'Credit Scoring/Rating',
      description:
        'Empower Customers with our Credit Scoring addon. Effortlessly manage payments, ensure accuracy and promptness. Seamlessly integrate, customize features, and receive real-time updates, allowing you to focus on growth and team development.',
    },
    {
      image: '/image/project/Food.png',
      title: 'Content Management System',
      description:
        'Manage financial content effortlessly with our Content Management System addon. Organize, store, and securely retrieve essential documents. Access statements, reports, invoices, and receipts anytime, anywhere. Stay in control, make informed decisions with ease.',
    },
    {
      image: '/image/project/virtual.jpg',
      title: 'Virtual Card Management',
      description:
        'Simply transactions with our Virtual Card Management addon. Transform payments, expenses, and reimbursements. Create purpose-driven virtual cards, set limits, and monitor transactions in real-time. Safeguard financial activities against unauthorized access.',
    },
  ];

  return (
    <section
      id='Portfolio'
      className='mt-20 mb-8 max-w-5xl mx-auto inset-x-0 bottom-0'>
      <p className='text-lg'>Our Portfolio*</p>
      <h2 className='text-3xl font-bold'>Recent Project</h2>
      <div className='mt-6 grid grid-cols-3 -md:grid-cols-2 -sm:grid-cols-1 justify-items-center gap-14'>
        {projects.map((project, index) => (
          <div key={index} className='w-80 py-10'>
            <img
              src={project.image}
              alt={project.title}
              className='mx-auto mb-3'
              style={{ height: '220px' }}
            />
            <h4 className='text-xl font-semibold mb-2'>{project.title}</h4>
            <p className='text-gray-500'>{project.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
}
export default Project;
