import React from 'react';
import './Header.css';

function Header() {
  return (
    <section id='header'>
      <nav className='flex max-w-6xl mx-auto justify-between py-4 px-16'>
        <div id='brand' className='flex'>
          <img src='/Vector.png' alt='logo' className='w-7 h-7' />
          <p className='text-lg mx-2 text-white'>ScoresAreUs</p>
        </div>
        <ul className='flex justify-end space-x-6'>
          <li>
            <a href='#Home' className='text-white hover:text-gray-400'>
              Home
            </a>
          </li>
          <li>
            <a href='#Service' className='text-white hover:text-gray-400'>
              Our Services
            </a>
          </li>
          <li>
            <a href='#Portfolio' className='text-white hover:text-gray-400'>
              Portfolio
            </a>
          </li>
        </ul>
      </nav>
      <div id='habbit' className='max-w-6xl mx-auto text-center -md:p-4'>
        <h1 className='mt-24 text-5xl -xl:text-3xl -md:text-2xl text-white font-bold'>
          Unleash Financial Access
          <br /> Build Your Business With Cutting-Edge Addons
        </h1>
        <p className='text-white mt-6 font-light text-gray-300'>
          Reach the peak of your financial performance with ScoresAreUs. Plug in
          Advanced Financial Services Addons. Elevate Your Efficiency,
          Streamline Operations, and Amplify Financial Potential. Tailored for
          Businesses, Managers, and Individuals Alike. Experience Excellence
          Now!
        </p>
      </div>
      <div>
        <img src='/main.png' alt='mainimg' className='main-img mt-20 m-auto' />
      </div>
    </section>
  );
}
export default Header;
